<template>
  <div>
    <div class="header" style="padding:10px 0;margin:0;">
      <div class="header_top">
        <img src="../../assets/agent.png" alt="" />
        <span class="center">代理管理</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">跟进的企业</span>
      </div>
      <el-button
        v-access
        data-ctrl="followEnterpriseAdd"
        @click="addObject"
        size="small"
        type="primary"
        icon="el-icon-plus"
      >
        新建
      </el-button>
    </div>
    <div style="display: flex;align-items: center;margin:20px 0">
      <span class="seach_text">关键字：</span>
      <el-input
        prefix-icon="el-icon-search"
        style="margin-right: 10px; width: 200px"
        v-model="formInline.keywords"
        placeholder="请输入客户名称"
        size="mini"
        @input="loadData('seath')"
      ></el-input>
    </div>
    <el-table :data="list" style="width: 100%">
      <el-table-column label="客户名称" width="200">
        <template slot-scope="scope">
          <div>
            <p
              @click="handleClick(scope.row)"
              style="color: #409eff; text-decoration: underline; cursor: pointer"
            >
              {{ scope.row.name }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="统一信用编码" width="160">
        <template slot-scope="scope">
          <p>{{ scope.row.idCardNumber }}</p>
        </template>
      </el-table-column>
      <el-table-column label="客户类型">
        <template slot-scope="scope">
          <p>{{ scope.row.type }}</p>
        </template>
      </el-table-column>
      <el-table-column label="客户地址" width="250">
        <template slot-scope="scope">
          {{ scope.row.address }}
        </template>
      </el-table-column>

      <el-table-column label="联系人">
        <template slot-scope="scope">
          {{ scope.row.contacts }}
        </template>
      </el-table-column>

      <el-table-column label="联系人电话">
        <template slot-scope="scope">
          {{ scope.row.contactsPhone }}
        </template>
      </el-table-column>

      <el-table-column label="费率">
        <template slot-scope="scope">
          <span v-if="scope.row.rate != '{}'">{{ scope.row.rate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag>
            <span v-if="scope.row.updateState === '1'">
              创建
            </span>
            <span v-if="scope.row.updateState === '2'">
              跟进中
            </span>
            <span v-if="scope.row.updateState === '3'">
              已完成
            </span>
            <span v-if="scope.row.updateState === '4'">
              已删除
            </span>
            <span v-if="scope.row.updateState === '5'">
              已失效
            </span>
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="跟进个人">
        <template slot-scope="scope">
          {{ scope.row.agentUserName }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateVal }}
        </template>
      </el-table-column>

      <el-table-column label="失效时间">
        <template slot-scope="scope">
          {{ scope.row.failureTime | dateVal }}
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="shuxian" v-if="scope.row.state !== '5'">
            <el-popover
              placement="top"
              width="400"
              trigger="manual"
              :ref="'popover-' + scope.row.id"
            >
              <div class="del-pop">
                <img src="../../assets/del.png" alt="" />
              </div>
              <div class="del-title">删除内容</div>
              <div class="del-sumtitle">是否删除本条内容？</div>
              <div class="del-btn">
                <el-button type="primary" @click="del(scope.row)" plain
                  >确认</el-button
                >
                <el-button type="info" @click="doCancel(scope.row.id)" plain
                  >取消</el-button
                >
              </div>
              <el-button
                type="text"
                slot="reference"
                @click="pOpen(scope.row.id)"
              >
                删除
              </el-button>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
          </div>

          <span
            class="clickColor"
            v-access
            data-ctrl="followEnterpriseEdit"
            @click="
              editDialogVisible = true;
              focusCode = scope.row.code;
            "
            >编辑</span
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor"> 共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">
        每页{{ pageSize }}条</span
      >
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <addDialog
      :dialogVisible.sync="addDialogVisible"
      @close="close()"
    ></addDialog>
    <editDialog
      :dialogVisible.sync="editDialogVisible"
      :code="focusCode"
      @close="close()"
    ></editDialog>
    <!--    
      路由配置 
    {
      path: 'followEnterprise/list',
      name: 'FollowEnterpriseList',
      component: () => import(/* webpackChunkName: "followEnterprise_list" */ '../views/followEnterprise/List.vue')
    }, {
      path: 'followEnterprise/detail',
      name: 'FollowEnterpriseDetail',
      component: () => import(/* webpackChunkName: "followEnterprise_detail" */ '../views/followEnterprise/Detail.vue')
    },{
      path: 'followEnterprise/add',
      name: 'FollowEnterpriseAdd',
      component: () => import(/* webpackChunkName: "followEnterprise_add" */ '../views/followEnterprise/Add.vue')
    },{
      path: 'followEnterprise/edit',
      name: 'FollowEnterpriseEdit',
      component: () => import(/* webpackChunkName: "followEnterprise_edit" */ '../views/followEnterprise/Edit.vue')
    }
  //接口：
  import { api } from "/src/api/base";
  //新增接口 
  export const FollowEnterpriseAdd= api("/followEnterprise")('/followEnterprise/followEnterprise.add.json')
  //查询列表接口
  export const FollowEnterpriseList= api("/followEnterprise")('/followEnterprise/followEnterprise.list.json')
  //查询详情接口
  export const FollowEnterpriseDetail= api("/followEnterprise")('/followEnterprise/followEnterprise.describe.json')
  //编辑接口
  export const FollowEnterpriseEdit= api("/followEnterprise")('/followEnterprise/followEnterprise.edit.json')
  //删除接口
  export const FollowEnterpriseDel= api("/contractTemplate")('/contractTemplate/contractTemplate.del.json')
  
  //文件上传接口
  export const FollowEnterpriseUploadPath='/followEnterprise/followEnterprise.upload.json'
  
  --></div>
</template>

<script>
//接口：
import { api } from '/src/api/base';
//新增接口
export const FollowEnterpriseAdd = api('/followEnterprise')(
  'followEnterprise.add.json',
);
//查询列表接口
export const FollowEnterpriseList = api('/followEnterprise')(
  'followEnterprise.list.json',
);
//查询详情接口
export const FollowEnterpriseDetail = api('/followEnterprise')(
  'followEnterprise.describe.json',
);
//编辑接口
export const FollowEnterpriseEdit = api('/followEnterprise')(
  'followEnterprise.edit.json',
);
//删除接口
export const FollowEnterpriseDel = api('/followEnterprise')(
  'followEnterprise.del.json',
);
export default {
  components: {
    addDialog: () => import('./Add'),

    editDialog: () => import('./Edit'),
  },
  data() {
    return {
      focusCode: '',
      value: false,
      pageSize: 10,
      pageNumber: 1,

      total: 0,
      formInline: {
        keywords: '',
      },
      list: [],
      addDialogVisible: false,
      editDialogVisible: false,
      dialogData: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;

      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.loadData();
    },
    putAway() {
      this.filterOpen = false;
      this.pageNumber = 1;
      this.loadData();
    },
    loadData(seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
      };
      FollowEnterpriseList(params).then((res) => {
        this.list = res.list;
        // alert('0000')
        this.total = res.total;
      });
    },
    onSubmit() {
      console.log('submit!');
    },
    goBack() {
      window.history.go(-1);
    },
    addObject() {
      this.addDialogVisible = true;
      console.log(this.formInline);
    },
    goEdit(obj) {
      this.focusCode = obj.code;
      this.editDialogVisible = true;
    },
    // goDetail(obj) {
    //   this.$router.push({
    //     path: "/followEnterprise/detail",
    //     query: obj,
    //   });
    // },
    pOpen(id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel(id) {
      this.pClose(id);
    },
    pClose(id) {
      this.$refs['popover-' + id].doClose();
    },
    del(obj) {
      FollowEnterpriseDel({ code: obj.code }).then((res) => {
        console.log(res);
        this.$message.success('内容成功删除！');
        this.doCancel(obj.id);
        this.loadData();
      });
    },
    close() {
      this.loadData();
    },
    handleClick(row) {
      console.log(row.code);
      this.$router.push({
        path: '/system/followEnterprise/detail',
        query: {
          code: row.code,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
